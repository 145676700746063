import { handleError } from '../errorHandling/error';
import Axios from './Axios';
import config from '../config/BaseConfig';

/**
 * TO GET ALL SITES
 * @returns {Promise.<Array.<object>,object>}
 */

export const getSites = (published = false) => {
    return new Promise(async (resolve, reject) => {
        let response = await Axios.get(published ? '/FlatoutData/PublishedSites' : '/Site', {
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
        });
        if (response && response.status) {
            let data = response.data;
            data = data.map((i) => ({
                name: i.name,
                country: i.country,
                id: i.id,
                active: i.active,
                key: i.id,
                value: i.name,
            }));
            data = data.filter((i) => i.active);
            resolve(data);
        }
    });
};

/**
 * TO GET UNITS
 * @param {int} siteID
 * @return {promise.<Array.<object>,object>}
 */

export const getUnits = (siteId, published = false) => {
    return new Promise(async (resolve, reject) => {
        let response = await Axios.get(published ? `/FlatoutData/${siteId}/PublishedUnits` : `/Site/${siteId}/Units`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
        });
        if (response && response.status) {
            let data = response.data;
            data = data.map((i) => ({ ...i, key: i.unitId, value: i.unitName }));
            resolve(data);
        }
    });
};

/**
 * To get vessels
 *
 * @param {int} unitId
 * @returns {Promise.<Array.<object>, object>}
 */

export const getVessels = (unitId, published = false) => {
    return new Promise(async (resolve, reject) => {
        let response = await Axios.get(
            published ? `/FlatoutData/${unitId}/PublishedVessels` : `/Unit/${unitId}/Vessels`,
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            }
        );
        if (response && response.status ) {
            let data = response.data;
            data = data.map((i) => ({ ...i, key: i.id, value: i.name }));
            resolve(data);
        }
    });
};

export const getZoneDetails = (siteUnitId, vesselId) => {
    return new Promise(async (resolve, reject) => {
        let response = await Axios.get(`/Unit/${siteUnitId}/Vessel/${vesselId}/Zones`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
        });
        if (response && response.status ) {
            let data = response.data;
            resolve(data);
        }
    });
};

/**
 * TO GET OUTAGES
 * @param {int} unitId
 * @returns {Promise.<Array.<object>, object>}
 */

export const getOutages = (unitId) => {
    return new Promise(async (resolve, reject) => {
        let response = await Axios.get(`/Unit/${unitId}/Outages`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
        });
        if (response && response.status ) {
            let data = response.data;
            data = data.map((i) => ({ key: i.id, value: i.name, outageStartDate: i.outageStartDate, outageEndDate: i.outageEndDate }));
            resolve(data);
        }
    });
};

/**
 * TO GET VERSIONS
 * @param {int} unitId
 * @param {in} vesselId
 * @param {int} outageId
 * @returns {Promise.<Array.<object>, object>}
 */
//  ?publishedOnly=true

export const getTemplateVersions = (unitId, vesselId , publishedOnly = false) => {
    var param = '';
    if (publishedOnly) {
        param = '?publishedOnly=true'
    }

    return new Promise(async (resolve, reject) => {
        let response = await Axios.get(`/FlatoutData/${unitId}/${vesselId}/MajorVersions${param}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
        });
        if (response && response.status && response.status === 200) {
            let versions = response.data;
            versions = versions.map((item) => {
                let vf = {
                    id: item,
                    key: item,
                    name: item + '.0',
                    value: item + '.0',
                };
                return vf;
            });
            resolve(versions);
        } else if (response && response.status && response.status === 204) {
            resolve([]);
        }
    });
};

export const getHistory = (siteUnitId, vesselId, outageId) => {
    return new Promise(async (resolve, reject) => {
        let response = await Axios.get(`/FlatoutRevision/${siteUnitId}/${vesselId}/${outageId}/History`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
            },
        });
        if (response && response.status && response.status === 200) {
            resolve(response.data);
        } else if (response && response.status && response.status === 204) {
            resolve([]);
        }
    });
};

export const getVesselMetaData = (siteUnitId, vesselId, version) => {
    return new Promise(async (resolve, reject) => {
        let response = await Axios.get(`/FlatoutData?siteUnitId=${siteUnitId}&vesselId=${vesselId}&version=${version}`);
        if (response && response.status && response.status === 200) {
            resolve(response);
        } else {
            resolve(handleError(response));
        }
    });
};

export class FlatoutDataAPI {
    static get uri() {
        return '/FlatoutData';
    }
    static get(siteUnitId, vesselId, version) {
        return new Promise(async (resolve, reject) => {
            let response = await Axios.get(
                `${this.uri}?siteUnitId=${siteUnitId}&vesselId=${vesselId}&version=${version}`
            );
            if (response && response.status && response.status === 200) {
                resolve(response.data);
            }
        });
    }

    static update(siteUnitId, vesselId, patchDocument) {
        return new Promise(async (resolve, reject) => {
            let response = await Axios.patch(
                `${this.uri}?siteUnitId=${siteUnitId}&vesselId=${vesselId}`,
                patchDocument
            );
            if (response && response.status && response.status === 200) {
                resolve(response);
            }
        });
    }

    /**
     * For publishing the vessel template
     * @param {int} siteUnitId
     * @param {int} vesselId
     * @returns {Promise.<boolean, object>}
     */

    static publish = (siteUnitId, vesselId) => {
        return new Promise(async (resolve, reject) => {
            let response = await Axios.put(`${this.uri}/${siteUnitId}/${vesselId}/Publish`);
            if (response && response.status && response.status === 200) resolve(true);
            else reject(handleError(response));
        });
    };

    static updateZoneMaps = (siteUnitId, vesselId, zones) => {
        return new Promise(async (resolve, reject) => {
            let response = await Axios.put(`ZoneMap/${siteUnitId}/Vessel/${vesselId}/UpdateZoneHierarchy`, zones);
            if (response && response.status && response.status === 200) resolve(true);
            else reject(handleError(response));
        });
    };

    static getSheets() {
        return new Promise(async (resolve, reject) => {
            let response = await Axios.get(`${this.uri}/Sheets`);
            if (response && response.status && response.status === 200) {
                resolve(response.data);
            } else {
                resolve(handleError(response));
            }
        });
    }

    static getDamageTypes() {
        return new Promise(async (resolve, reject) => {
            let response = await Axios.get(`${this.uri}/DamageTypes`);
            if (response && response.status && response.status === 200) {
                resolve(response.data);
            }
        });
    }

    static getMaintenanceScope() {
        return new Promise(async (resolve, reject) => {
            let response = await Axios.get(`${this.uri}/MaintenanceScopes`);
            if (response && response.status && response.status === 200) {
                resolve(response.data);
            } else {
                resolve(handleError(response));
            }
        });
    }

    static getAllUnitsInSite(siteId) {
        return new Promise(async (resolve, reject) => {
            let response = await Axios.get(`/Site/${siteId}/Units`);
            if (response && response.status && response.status === 200) {
                resolve(response.data);
            } else {
                resolve(handleError(response));
            }
        });
    }

    static createOutage(siteUnitId, startDate, endDate, name) {
        let outageObj = {
            id: 0,
            name: name,
            comments: '',
            siteName: '',
            unitName: '',
            siteUnitID: siteUnitId,
            outageTypeId: 2,
            outageStartDate: new Date(Date.UTC(startDate.getFullYear(),
                                                startDate.getMonth(),
                                                startDate.getDate())),
            outageDate: "",
            outageEndDate: new Date(Date.UTC(endDate.getFullYear(),
                                              endDate.getMonth(),
                                              endDate.getDate())),
            modifiedBy: "",
            generalComments: "",
            createdBy: '',
            createdDate: new Date()
        };

        return new Promise(async (resolve, reject) => {
            let response = await Axios.post('/Outage', outageObj, {baseURL: config.baseURL.replace("/api", "")});
            if (response && response.status && response.status === 200) {
                resolve(response.data);
            } else {
                reject(handleError(response));
            }
        });
    }
}
