import React, { useEffect, useRef, useState } from 'react';
import ToolsBarUploadTemplates from '../../components/tools/ToolsBarUploadTemplates';
import TopPanelUploadTemplates from '../../components/topPanel/TopPanelUploadTemplates';
import Viewport from '../../components/viewport/Viewport';
import './UploadTemplates.scss';
import {
    deleteImageForUploadTemplates,
    getTemplateImage,
    uploadImageForUploadTemplates,
} from '../../services/ImageAPI';
import { FlatoutDataAPI, getZoneDetails } from '../../services/FlatoutDataAPI';
import { useDispatch, useSelector } from 'react-redux';
import { ActionType } from '../../utils/Action';
import { verifyImage } from '../../utils/ImageValidation';
import { confirmDialog } from 'primereact/confirmdialog';
import { InputNumber } from 'primereact/inputnumber';
import { hideModal, showModal } from '../../modal/Modal';
import { TemplateAPI } from '../../services/TemplateAPI';
import ZoneBoxUploadTemplates from '../../components/zoneBoxUploadTemplates/ZoneBoxUploadTemplates';
import { Colors } from '../../utils/Colors';
import { PatternStyle } from '../../config/Constants';
import { loadPatternCanvas } from '../../utils/Pattern';
import {
    convertArrayToBase64,
    convertBase64ToArray,
    convertBase64ToArrayOfArrays,
    convertSetofArraysToBase64,
    deepCopy,
    getCenterOfArea,
    getPatternType,
    getValue,
    insidePolygon,
    PatternType,
    sleep,
} from '../../utils/Helper';
import { CellDetection } from '../../utils/CellDetection';
import { Coord } from '../../utils/Coord';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MetaData } from '../../models/MetaData';
import { PerfMon } from '../../utils/PerfMon';
import ZoneWizard from '../../components/zoneWizard/ZoneWizard';
import { setTool } from '../../redux/reducer/ToolReducer';
import { Tessellation } from '../../utils/Tessellation';
import { setTemplateVersion } from '../../redux/reducer/HierarchyReducer';
import { StaticStates } from '../../utils/StaticeStates';
import { setLoadingBlocker } from '../../redux/reducer/LoadingBlockerReducer';

// const DEBUG = !(process.env.REACT_APP_ENV_NAME in ['Dev', 'Test', 'Prod']);
const DEBUG = false;
PerfMon.debug = DEBUG;
CellDetection.debug = DEBUG;

export default function UploadTemplates({ ...props }) {
    const selectedTool = useSelector((state) => state.tool.tool);
    var dispatch = useDispatch();
    const hierarchy = useSelector((state) => state.hierarchy);

    const [imageData, setImageData] = useState(null);
    const [validating, setValidating] = useState(false);
    const [analysing, setAnalysing] = useState(false);
    const [sheetPropDialogVisible, setSheetPropDialogVisible] = useState(false);
    const [showZoneWizard, setShowZoneWizard] = useState(false);
    const [flatout, setFlatout] = useState(null);
    const [sheets, setSheets] = useState([]);
    const [scale, setScale] = useState(1);
    const [sheet, setSheet] = useState(null);
    const [zones, setZones] = useState([]);
    const [zonesBackup, setZonesBackup] = useState([]);
    const [zoneColorMap, setZoneColorMap] = useState([]);
    const [selectedZone, setSelectedZone] = useState(31);
    const [locked, setLocked] = useState(false);

    const filePicker = useRef();
    const topPanelUploadTemplatesRef = useRef();
    const viewportRef = useRef();
    const imageUploadInterval = useRef(0);
    const zoneWizRef = useRef();

    const BLACK_THRESHOLD = 30;

    const user = useSelector((state) => state.user);

    // Progressive Cell detection and rendering
    const [, setMaxManhattanRadius] = useState(20);
    const [maxSamplingRadius, setMaxSamplingRadius] = useState(32);
    const [minSamplingRadius, setMinSamplingRadius] = useState(10);
    const [maxAllowedCellArea, setMaxAllowedCellArea] = useState(6000);
    const [imageError, setImageError] = useState(null);

    // Fill Swipe
    const [fillCellCoords, setFillCellCoords] = useState([]);
    // Fill Region
    const [fillRectStart, setFillRectStart] = useState(new Coord());
    const [fillRectEnd, setFillRectEnd] = useState(new Coord());

    const [queueFullDraw, setQueueFullDraw] = useState(false);

    const [isSaveRequired, setIsSaveRequired] = useState(false);

    const handleImageUpload = () => {
        filePicker.current.click();
    };

    const handleImageReUpload = () => {
        confirmDialog({
            message: 'Are you sure you want to re-upload the vessel?',
            header: 'Re-upload?',
            icon: 'pi pi-exclamation-triangle',
            className: ' aq-dialog',
            maskClassName: 'aq-dialog-backdrop',
            resizable: false,
            draggable: false,
            acceptClassName: 'aq-btn aq-btn-md aq-primary',
            acceptIcon: 'pi pi-cloud-upload',
            acceptLabel: 'Upload',
            rejectClassName: 'aq-btn aq-btn-md',
            rejectIcon: 'pi pi-times',
            rejectLabel: 'Cancel',
            accept: async () => {
                try {
                    filePicker.current.click();
                } catch (error) {}
            },
        });
    };

    /**
     * This handler function is responsible for key board support.
     * @type {event} e
     */
    const handleKeyBoardEvents = (event) => {
        if (event) {
            let cls1 = document.body.getElementsByClassName('p-dropdown-panel');
            let cls2 = document.body.getElementsByClassName('p-dialog-header');
            let key = event.key.toLowerCase();
            hideModal();
            if (
                cls1.length === 0 &&
                cls2.length === 0 &&
                viewportRef.current &&
                viewportRef.current.getIsImageAvailable()
            ) {
                switch (key) {
                    case 'w':
                        dispatch(setTool(ActionType.TRANSFORM));
                        break;
                    case 'c':
                        dispatch(setTool(ActionType.FILLCELL));
                        break;
                    case 'r':
                        dispatch(setTool(ActionType.FILLREGION));
                        break;
                    case 'x':
                        dispatch(setTool(ActionType.ERASE));
                        break;
                    case '+':
                    case '=':
                        viewportRef.current.zoomIn();
                        break;
                    case '-':
                    case '_':
                        viewportRef.current.zoomOut();
                        break;
                    case 'f':
                        viewportRef.current.fitToScreen();
                        break;
                    default:
                }
            }
        }
    };

    useEffect(() => {
        FlatoutDataAPI.getSheets().then((response) => {
            setSheets(response);
        });

        window.addEventListener('keypress', handleKeyBoardEvents);

        return () => {
            StaticStates.FlatoutDataAcquired = false;
            StaticStates.FlatoutIsPublished = false;
            StaticStates.UploaderLockStatusAcquired = false;
            StaticStates.UploaderLockTimerActive = false;
            StaticStates.InspectorLockTimerActive = false;
            StaticStates.InspectorLockStatusAcquired = false;

            window.removeEventListener('keypress', handleKeyBoardEvents);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * This useEffect function render once the page is load and refresh.
     */

    useEffect(() => {
        setFlatout(null);
        setScale(1);
        setSheet(null);
        CellDetection.detectedCells = [];
        CellDetection.scale = 1;
        CellDetection.imageBuffer = null;
        setImageError(null);
        viewportRef.current.reset();
        setImageData(null);
        setZones([]);
        StaticStates.FlatoutDataAcquired = false;
        StaticStates.UploaderLockStatusAcquired = false;
        StaticStates.UploaderLockTimerActive = false;

        if (
            hierarchy.siteId > 0 &&
            hierarchy.siteUnitId > 0 &&
            hierarchy.vesselId > 0 &&
            hierarchy.templateVersion >= 0
        ) {
            viewportRef.current.showLoader('Loading', true);
            fetchImage()
                .then(() => {
                    if (imageError === null) {
                        fetchZoneDetails()
                            .then(() => {
                                fetchFlatoutData();
                                setQueueFullDraw(true);
                            })
                            .catch(() => {
                                // viewportRef.current.hideLoader();
                                setQueueFullDraw(false);
                            });
                    }
                })
                .catch((error) => {
                    console.debug(`Error is ${error}`);
                    viewportRef.current.hideLoader();
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hierarchy]);

    useEffect(() => {
        if (queueFullDraw && flatout && zoneColorMap) {
            fetchTemplateData().then(async () => {
                viewportRef.current.showLoader('Rendering');
                CellDetection.paintCtx = viewportRef.current.getPaintContext();
                if (CellDetection.detectedCells.length > 2000) {
                    console.warn(
                        `Rendering ${CellDetection.detectedCells.length} cells at once! Viewport performance will be impacted.`
                    );
                }

                PerfMon.start('Rendering all cells');
                await CellDetection.drawAll(zoneColorMap, () => {
                    viewportRef.current.reRender();
                });
                PerfMon.end();
                setQueueFullDraw(false);
                viewportRef.current.hideLoader();
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queueFullDraw, flatout, zoneColorMap]);

    /**
     * This useEffect function render on the dependencies of flatout and sheets.
     * it is checking sheet size and their scale.
     */

    useEffect(() => {
        if (flatout != null && imageError === null) {
            if (flatout.mmScale && flatout.sheetId) {
                setScale(flatout.mmScale);
                var _invalidFields = [];
                if (!(flatout.mmScale % 25 === 0 || flatout.mmScale % 8 === 0)) _invalidFields.push('scale');
                if (sheets.length > 0) {
                    setSheet(sheets.find((s) => s.id === flatout.sheetId));
                    if (flatout.sheetId === 0 || flatout.sheetId >= sheets[sheets.length - 2])
                        _invalidFields.push('sheet size');
                }

                if (
                    _invalidFields.length > 0 &&
                    topPanelUploadTemplatesRef.current.getLatestVersion().key === hierarchy.templateVersion &&
                    !flatout.isPublished
                ) {
                    setTimeout(() => {
                        showModal({
                            title: 'Please Verify',
                            message: 'Please check if the detected ' + _invalidFields.join(' & ') + ' is correct',
                            class: 'warning',
                            okCallBack: () => {
                                setSheetPropDialogVisible(true);
                            },
                        });
                    }, 200);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flatout, sheets]);
    useEffect(() => {
        CellDetection.scale = scale;
        setMaxManhattanRadius(Math.trunc((40 * 50) / scale));
        setMaxSamplingRadius(Math.trunc((1000 * 25) / scale));
        setMinSamplingRadius(Math.trunc((8 * 50) / scale));
        setMaxAllowedCellArea(Math.trunc((3000 * 2500) / (scale * scale)));
    }, [scale]);

    const fetchImage = async () => {
        if (imageUploadInterval.current) {
            clearInterval(imageUploadInterval.current);
        }

        console.info('Attempting to fetch drawing…');
        viewportRef.current.showLoader('Loading Drawing', true);
        await tryFetchImage();
    };

    /**
     * This below function fetch template image response which is coming from API and set their value.
     */

    const tryFetchImage = async (pollCount = 0) => {
        if (pollCount > 36) {
            setImageError('Image processing timed out. Please reupload the image and try again');
            showModal({
                title: 'Something went wrong',
                message: 'Image processing timed out. Please reupload the image and try again ',
                class: 'error',
            });
            setAnalysing(false);
            viewportRef.current.hideLoader();
            return;
        }
        dispatch(setLoadingBlocker(true));
        try {
            let imageResponse = await getTemplateImage(
                hierarchy.siteUnitId,
                hierarchy.vesselId,
                hierarchy.templateVersion
            );
            if (imageResponse.status === 200) {
                PerfMon.end();
                if (typeof imageResponse.data === 'string' || imageResponse.data instanceof String) {
                    setImageError(imageResponse.data);
                    showModal({
                        title: 'Oh no!',
                        message: imageResponse.data,
                        class: 'error',
                    });
                    setAnalysing(false);
                    viewportRef.current.hideLoader();
                } else {
                    console.info('Got image. Renderring…');
                    setAnalysing(false);
                    setImageData(imageResponse.data.data);
                }
            } else if (imageResponse.status === 204) {
                console.info('Still analysing. Retrying after 5 seconds…');
                setAnalysing(true);
                PerfMon.start('Image Analysis');
                viewportRef.current.showLoader('Analysing', true);
                await sleep(5000);
                await tryFetchImage(pollCount + 1);
            }
            dispatch(setLoadingBlocker(false));
        } catch (err) {
            console.error(err);
        }
    };

    /**
     * This below function fetch flatout data which gives the information about image whether it is published or not .
     */

    const fetchFlatoutData = async () => {
        var flatout = await FlatoutDataAPI.get(hierarchy.siteUnitId, hierarchy.vesselId, hierarchy.templateVersion);
        setFlatout(flatout);
        StaticStates.FlatoutIsPublished = flatout.isPublished;
        StaticStates.FlatoutDataAcquired = true;
    };

    const fetchTemplateData = async () => {
        viewportRef.current.showLoader('Loading Template ');
        dispatch(setLoadingBlocker(true));
        var template = await TemplateAPI.get(hierarchy.siteUnitId, hierarchy.vesselId, hierarchy.templateVersion);
        if (template.cells) {
            await loadTemplateData(template.cells, template.tessellated);
        }
        dispatch(setLoadingBlocker(false));
    };

    /**
     * This below function fetches zone deatils.
     * set the zone and color based upon the zone details.
     */

    const fetchZoneDetails = async () => {
        await getZoneDetails(hierarchy.siteUnitId, hierarchy.vesselId)
            .then((_zones) => {
                if (_zones) {
                    dispatch(setLoadingBlocker(true));
                    viewportRef.current.showLoader('Loading Zones', true);
                    setSelectedZone(_zones[0].id);
                    for (let i = 0; i < _zones.length; i++) {
                        _zones[i].key = i;
                        var hasChild = false;
                        for (let j = 0; j < _zones.length; j++) {
                            if (_zones[j].parentZoneId === _zones[i].id) {
                                hasChild = true;
                            }
                        }
                        _zones[i].isParent = hasChild;
                    }
                    setZones(_zones);
                    setColorBasedOnZoneData(_zones);
                } else {
                    showModal({
                        title: 'No Zones Present',
                        message:
                            'No Zones are present . Please link zones in RMS ⟩ Equipment Setup ⟩ Site-Unit-Vessel-Zone',
                        class: 'warning',
                        okText: 'OK',
                    });
                }
                dispatch(setLoadingBlocker(false));
            })
            .catch((err) => console.debug(err));
    };

    useEffect(() => {
        if (flatout && zones.length > 0) {
            if (flatout.isPublished) {
                dispatch(setTool(ActionType.TRANSFORM));
            } else {
                setZonesBackup(deepCopy(zones));
                setShowZoneWizard(imageError == null && StaticStates.UploaderLockStatusAcquired);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [zones, flatout, dispatch]);

    useEffect(() => {}, [scale, sheet]);

    const setColorBasedOnZoneData = (_zones) => {
        let _zoneColorsMap = [];
        let zonesLength = _zones.length;
        if (Colors.length >= zonesLength) {
            for (let i = 0; i < zonesLength; i++) {
                _zoneColorsMap.push({ zoneId: _zones[i].id, color: Colors[i + 1], type: PatternStyle.SOLID });
            }
        } else {
            let fgIndex = 0;
            let i = 0;
            for (; i < Colors.length - 1; i++) {
                _zoneColorsMap.push({
                    zoneId: _zones[i].id,
                    color: Colors[i + 1],
                    type: PatternStyle.SOLID,
                });
                fgIndex++;
            }

            fgIndex = 1;
            let bgIndex = 0;
            let patternTypeIndex = 0;
            let returnedCanvas = null;
            for (; i < zonesLength; i++) {
                let size = patternTypeIndex >= 3 ? 4 : 2;
                returnedCanvas = loadPatternCanvas(
                    Colors[fgIndex],
                    Colors[bgIndex],
                    size,
                    PatternType[patternTypeIndex]
                );
                _zoneColorsMap.push({
                    zoneId: _zones[i].id,
                    canvas: returnedCanvas,
                    type: PatternType[patternTypeIndex],
                });
                if (fgIndex + 1 < Colors.length) {
                    fgIndex++;
                    bgIndex++;
                } else {
                    fgIndex = 1;
                    bgIndex = 0;
                    patternTypeIndex++;
                }
            }
            returnedCanvas = null;
        }

        setZoneColorMap([..._zoneColorsMap]);
    };

    const handleZoneSelection = (zoneDetails) => {
        setSelectedZone(zoneDetails.id);
        let type = getPatternType(zoneColorMap, zoneDetails.id);
        CellDetection.associatedZoneIsPatternFilled = type > 0 ? true : false;
    };

    /**
     * This below function responsible for uploading, validating and verifying the image.
     */
    async function onChangeImage(e) {
        if (!e?.target?.files && !e?.target?.files[0]) {
            return;
        }
        try {
            setValidating(true);
            dispatch(setTemplateVersion(-1));
            viewportRef.current.showLoader('Validating', true);
            dispatch(setLoadingBlocker(true));
            PerfMon.start('Image verification');
            let result = await verifyImage(e.target.files[0]);
            PerfMon.end();
            if (result.status === 200) {
                const formData = new FormData();
                formData.append('imageFile', e.target.files[0], e.target.files[0].name);
                viewportRef.current.showLoader('Uploading', true);
                PerfMon.start('Image uploading');
                let uploadVessel = await uploadImageForUploadTemplates(
                    hierarchy.siteUnitId,
                    hierarchy.vesselId,
                    formData
                );
                if (uploadVessel) {
                    PerfMon.end();
                    showModal({
                        title: 'Vessel upload',
                        message: 'Vessel uploaded successfully.',
                        class: 'success',
                        okText: 'OK',
                    });
                    fetchVesselVersion();
                }
            }
            dispatch(setLoadingBlocker(false));
        } catch (e) {
            viewportRef.current.hideLoader();
        } finally {
            setValidating(false);
            filePicker.current.value = '';
            setImageError(null);
        }
    }

     /**
     * This handler function where user can delete the image.
     */

    const handleImageDelete = () => {
        confirmDialog({
            message: 'Are you sure you want to delete the vessel?',
            header: 'Delete?',
            icon: 'pi pi-exclamation-triangle',
            className: 'aq-dialog',
            maskClassName: 'aq-dialog-backdrop',
            resizable: false,
            draggable: false,
            acceptClassName: 'aq-btn aq-btn-md aq-destructive',
            acceptIcon: 'pi pi-trash',
            acceptLabel: 'Delete',
            rejectClassName: 'aq-btn aq-btn-md',
            rejectIcon: 'pi pi-times',
            rejectLabel: 'Cancel',
            accept: async () => {
                try {
                    viewportRef.current.showLoader('Deleting', true);
                    let response = await deleteImageForUploadTemplates(hierarchy.siteUnitId, hierarchy.vesselId);
                    if (response) {
                        showModal({
                            title: 'Deleted',
                            message: 'Vessel template deleted successfully.',
                            class: 'success',
                            okText: 'OK',
                        });
                        setImageError(null);
                        CellDetection.detectedCells = [];
                        CellDetection.imageBuffer = null;
                        viewportRef.current.reset();
                        fetchVesselVersion();
                    }

                    viewportRef.current.hideLoader();
                } catch (error) {
                    console.error(error);
                    viewportRef.current.hideLoader();
                }
            },
        });
    };

   
    const loadTemplateData = async (cells, isTessellated) => {
        CellDetection.detectedCells = [];
        for (let i = 0; i < cells.length; i++) {
            let _cell = deepCopy(cells[i]);
            _cell.g.ap = convertBase64ToArray(_cell.g.ap);
            let encodedSubtractiveData = convertBase64ToArrayOfArrays(_cell.g.sPs);
            _cell.g.sPs = new Set(encodedSubtractiveData.map((data) => [...data]));

            let _centroid = [..._cell.g.c];
            _cell.g.c = new Coord(_centroid[0], _centroid[1]);

            let _scale = [..._cell.g.s];
            _cell.g.s = new Coord(_scale[0], _scale[1]);

            _cell.metadata = new MetaData(getPatternType(zoneColorMap, _cell.zId) > 0);

            if (isTessellated) {
                _cell.g = Tessellation.decode(_cell.g, CellDetection.detectedCells);
            }

            CellDetection.detectedCells.push(_cell);
        }
    };

    /**
     * This method is used to get all the cells present in the cells list
     */
    const createFlatoutTemplateCellPayload = () => {
        let templateCellsPayload = [];
        if (CellDetection.detectedCells.length > 0) {
            for (let i = 0; i < CellDetection.detectedCells.length; i++) {
                var _cell = CellDetection.detectedCells[i];
                let additiveDataArray = Array.from(_cell.g.ap);
                let _additiveData = convertArrayToBase64(additiveDataArray);
                let _subtractiveData = convertSetofArraysToBase64(_cell.g.sPs);

                let templateCell = deepCopy(_cell);
                templateCell.metadata = null;
                templateCell.g.c = [templateCell.g.c.x, templateCell.g.c.y];
                templateCell.g.s = [templateCell.g.s.x, templateCell.g.s.y];
                templateCell.g.ap = _additiveData;
                templateCell.g.sPs = [..._subtractiveData];

                templateCellsPayload.push(templateCell);
            }
        }

        return templateCellsPayload;
    };

    const handleFlatoutSave = () => {
        confirmDialog({
            message: 'Are you sure you want to proceed?',
            header: 'Save Flatout Data',
            icon: 'pi pi-save',
            className: 'aq-dialog',
            maskClassName: 'aq-dialog-backdrop',
            resizable: false,
            draggable: false,
            acceptClassName: 'aq-btn aq-btn-md aq-suggestive',
            acceptIcon: 'pi pi-save',
            acceptLabel: 'Save',
            rejectClassName: 'aq-btn aq-btn-md',
            rejectIcon: 'pi pi-times',
            rejectLabel: 'Cancel',
            accept: async () => {
                await save();
            },
        });
    };

    const save = async (showAlert = true) => {
        // Save sheet size and scale
        var patchDocument = [
            {
                value: scale,
                path: '/mmScale',
                op: 'replace',
            },
            {
                value: sheet.id,
                path: '/sheetId',
                op: 'replace',
            },
        ];
        try {
            viewportRef.current.showLoader('Saving Flatout', true);
            var scaleSheetResponse = await FlatoutDataAPI.update(
                hierarchy.siteUnitId,
                hierarchy.vesselId,
                patchDocument
            );
            if (scaleSheetResponse.status === 200) {
                // Update template
                if (CellDetection.detectedCells.length > 0) {
                    var cellSaveResponse = await TemplateAPI.addOrReplaceCells(
                        hierarchy.siteUnitId,
                        hierarchy.vesselId,
                        hierarchy.templateVersion,
                        createFlatoutTemplateCellPayload()
                    );
                    if (cellSaveResponse.status === 200) {
                        if (showAlert) {
                            showModal({
                                title: 'Saved',
                                message: 'Flatout template data has been saved successfully',
                                class: 'success',
                                okText: 'OK',
                            });
                            setIsSaveRequired(false);
                        }
                    }
                } else if (showAlert) {
                    showModal({
                        title: 'No Cells!',
                        message: 'No cells were identified. Please save again after identifying some cells',
                        class: 'warning',
                        okText: 'CONTINUE',
                    });
                }
            }

            if (showAlert) {
                viewportRef.current.hideLoader();
            }
        } catch (err) {
            viewportRef.current.hideLoader();
        }
    };

    const handleImagePublish = () => {
        if (CellDetection.detectedCells.length === 0) {
            showModal({
                title: 'No Cells!',
                message: 'Please add cells before publishing',
                class: 'warning',
            });

            return;
        }
        confirmDialog({
            message: 'Publishing the vessel will save the zone map automatically. Are you sure you want to proceed?',
            header: 'Publish Vessel Image',
            icon: 'pi pi-angle-double-up',
            className: 'aq-dialog',
            maskClassName: 'aq-dialog-backdrop',
            resizable: false,
            draggable: false,
            acceptClassName: 'aq-btn aq-btn-md aq-suggestive',
            acceptIcon: 'pi pi-angle-double-up',
            acceptLabel: 'Publish',
            rejectClassName: 'aq-btn aq-btn-md',
            rejectIcon: 'pi pi-times',
            rejectLabel: 'Cancel',
            accept: async () => {
                if (isSaveRequired) {
                    await save(false);
                }

                viewportRef.current.showLoader('Publishing', true);
                try {
                    let response = await FlatoutDataAPI.publish(hierarchy.siteUnitId, hierarchy.vesselId);
                    if (response) {
                        setTimeout(() => {
                            var _flatout = deepCopy(flatout);
                            _flatout.isPublished = true;
                            _flatout.mmScale = scale;
                            _flatout.sheetId = sheet.id;
                            _flatout.modifiedBy = user.id;
                            _flatout.modifiedDate = new Date();
                            setFlatout(_flatout);
                            showModal({
                                title: 'Published!',
                                message: 'Vessel template published successfully',
                                class: 'success',
                            });
                        }, 200);
                    }

                    viewportRef.current.hideLoader();
                } catch (err) {
                    viewportRef.current.hideLoader();
                }
            },
        });
    };

    const fetchVesselVersion = () => {
        topPanelUploadTemplatesRef.current.fetchVersions();
    };

    const handleInputStart = async (x, y) => {
        switch (selectedTool) {
            case ActionType.FILLCELL:
                let _fillCellCoords = fillCellCoords.slice();
                _fillCellCoords.push({ x: x, y: y });
                setFillCellCoords([..._fillCellCoords]);
                break;
            case ActionType.FILLREGION:
                setFillRectStart(new Coord(x, y));
                setFillRectEnd(new Coord(x, y));
                break;
            default:
        }
    };

    const handleInputEnd = async (x, y) => {
        setIsSaveRequired(true);
        switch (selectedTool) {
            case ActionType.FILLCELL:
                let _fillCellCoords = fillCellCoords.slice();
                _fillCellCoords.push({ x: x, y: y });
                setFillCellCoords([..._fillCellCoords]);
                identifyCellsProcess().then(() => {
                    setFillCellCoords([]);
                });
                break;
            case ActionType.ERASE:
                CellDetection.width = 4000;
                CellDetection.height = 2666;
                CellDetection.imageBuffer = viewportRef.current
                    .getReadOnlyContext()
                    .getImageData(0, 0, 4000, 2666).data;
                CellDetection.paintCtx = viewportRef.current.getPaintContext();
                CellDetection.detectHole(zoneColorMap, { x: x, y: y }, DEBUG).then(() => {
                    CellDetection.imageBuffer = null;
                });
                break;
            case ActionType.FILLREGION:
                viewportRef.current.clearPreviewCanvas();
                setFillRectEnd(new Coord(x, y));
                CellDetection.width = 4000;
                CellDetection.height = 2666;
                CellDetection.imageBuffer = viewportRef.current
                    .getReadOnlyContext()
                    .getImageData(0, 0, 4000, 2666).data;
                CellDetection.paintCtx = viewportRef.current.getPaintContext();
                CellDetection.associatedZoneId = selectedZone;
                viewportRef.current.showLoader('Rendering');
                let areaToDetect = Math.abs((fillRectEnd.x - fillRectStart.x) * (fillRectEnd.y - fillRectStart.x));
                if (areaToDetect > 10500000 / flatout.mmScale) {
                    console.warn('Attempting to detect cells in a huge area! Viewport performance may be impacted.');
                }

                PerfMon.start('Mass cell identification');
                await identifyCellRegionProgressive(maxSamplingRadius).then(() => {
                    CellDetection.imageBuffer = null;
                    viewportRef.current.hideLoader();
                });
                PerfMon.end();
                break;
            default:
        }
    };

    const handleInputUpdate = async (x, y) => {
        switch (selectedTool) {
            case ActionType.FILLCELL:
                let _fillCellCoords = fillCellCoords.slice();
                _fillCellCoords.push({ x: x, y: y });
                setFillCellCoords([..._fillCellCoords]);
                break;
            case ActionType.FILLREGION:
                viewportRef.current.clearPreviewCanvas();
                let previewContext = viewportRef.current.getPreviewContext();
                previewContext.strokeStyle = 'rgb(128, 128, 128)';
                previewContext.lineWidth = 2;
                previewContext.setLineDash([4, 1]);
                previewContext.globalCompositeOperation = 'exclusion';
                previewContext.strokeRect(fillRectStart.x, fillRectStart.y, x - fillRectStart.x, y - fillRectStart.y);
                setFillRectEnd(new Coord(x, y));
                previewContext.globalCompositeOperation = 'source-over';
                previewContext.setLineDash([]);
                break;
            default:
        }
    };

    const identifyCellsProcess = async () => {
        CellDetection.width = 4000;
        CellDetection.height = 2666;
        CellDetection.imageBuffer = viewportRef.current.getReadOnlyContext().getImageData(0, 0, 4000, 2666).data;
        CellDetection.paintCtx = viewportRef.current.getPaintContext();
        CellDetection.associatedZoneId = selectedZone;
        viewportRef.current.showLoader('Rendering');
        PerfMon.start('Cell identification');
        for (let i = 0; i < fillCellCoords.length; i++) {
            await CellDetection.detect(fillCellCoords[i], zoneColorMap, [], DEBUG);
        }

        PerfMon.end();
        viewportRef.current.hideLoader();
        CellDetection.imageBuffer = null;
    };

    const identifyCellRegionProgressive = async (samplingRadius) => {
        await identifyCellRegionProcess(samplingRadius);
        if (samplingRadius >= minSamplingRadius) {
            await identifyCellRegionProgressive(samplingRadius >> 1); // sampleRadius / 2
        }
    };

    const identifyCellRegionProcess = async (samplingRadius = 0) => {
        var startX = fillRectStart.x;
        var startY = fillRectStart.y;
        var endX = fillRectEnd.x;
        var endY = fillRectEnd.y;

        var startingCoord = new Coord(Math.min(startX, endX), Math.min(startY, endY));
        setFillRectStart(startingCoord);
        var endingCoord = new Coord(Math.max(startX, endX), Math.max(startY, endY));
        setFillRectEnd(endingCoord);

        var imageBuffer = viewportRef.current.getReadOnlyContext().getImageData(0, 0, 4000, 2666).data;
        let fillStartCoords = [];
        for (var y = startingCoord.y; y < endingCoord.y; y = Math.min(y + samplingRadius, endingCoord.y)) {
            for (var x = startingCoord.x; x < endingCoord.x; x = Math.min(x + samplingRadius, endingCoord.x)) {
                if (getValue(imageBuffer, x, y, BLACK_THRESHOLD)) {
                    var areaCenters = getCenterOfArea(imageBuffer, { x: x, y: y }, BLACK_THRESHOLD);
                    if ((areaCenters.rx - areaCenters.lx) * (areaCenters.by - areaCenters.ty) < maxAllowedCellArea) {
                        if (getValue(imageBuffer, areaCenters.ax, areaCenters.ay, BLACK_THRESHOLD)) {
                            fillStartCoords.push({ x: areaCenters.ax, y: areaCenters.ay });
                        }
                    }
                }
            }
        }

        if (DEBUG) {
            let ctx = viewportRef.current.getPaintContext();
            ctx.fillStyle = 'rgb(0, 0, 255)';
            fillStartCoords.forEach((point) => {
                ctx.fillRect(point.x, point.y, 2, 2);
            });
        } else {
            let cellsIndexArray = [];
            let path = new Uint16Array(8);
            path[0] = startingCoord.x;
            path[1] = startingCoord.y;

            path[2] = startingCoord.x;
            path[3] = endingCoord.y;

            path[4] = endingCoord.x;
            path[5] = endingCoord.y;

            path[6] = endingCoord.x;
            path[7] = startingCoord.y;

            for (let i = 0; i < CellDetection.detectedCells.length; i++) {
                if (insidePolygon(path, CellDetection.detectedCells[i].g.c)) {
                    cellsIndexArray.push(i);
                }
            }

            await sleep(1);
            for (let i = 0; i < fillStartCoords.length; i++) {
                if (i % 52 === 0) {
                    await sleep(1);
                }

                await CellDetection.detect(fillStartCoords[i], zoneColorMap, cellsIndexArray, DEBUG);
            }
        }
    };

    const saveZoneHierarchy = async (changed) => {
        setShowZoneWizard(false);
        if (changed) {
            if (!flatout.isPublished) {
                if (CellDetection.detectedCells.length > 0) {
                    confirmDialog({
                        message: 'Changing the zone maps will remove all identified cells. Do you want to proceed?',
                        header: 'Change Zone Hierarchy?',
                        icon: 'pi pi-exclamation-triangle',
                        className: 'aq-dialog',
                        maskClassName: 'aq-dialog-backdrop',
                        resizable: false,
                        draggable: false,
                        acceptClassName: 'aq-btn aq-btn-md aq-warning',
                        acceptIcon: 'pi pi-save',
                        acceptLabel: 'Yes',
                        rejectClassName: 'aq-btn aq-btn-md',
                        rejectIcon: 'pi pi-times',
                        rejectLabel: 'Cancel',
                        accept: async () => {
                            viewportRef.current.showLoader('Configuring Zones', true);
                            // if template has existing zones, delete them
                            CellDetection.detectedCells = [];
                            viewportRef.current.clearPaintCanvas();
                            // save the zones and provide a fresh drawing board to the user
                            try {
                                var success = await FlatoutDataAPI.updateZoneMaps(
                                    hierarchy.siteUnitId,
                                    hierarchy.vesselId,
                                    zones
                                );
                                if (success) {
                                    showModal({
                                        title: 'Done!',
                                        message: 'Zone hierarchy saved successfully. Please identify zones again.',
                                        class: 'success',
                                    });
                                }
                            } catch (err) {
                                console.error(err);
                            } finally {
                                viewportRef.current.hideLoader();
                            }
                        },
                    });
                } else {
                    viewportRef.current.showLoader('Configuring Zones', true);
                    try {
                        CellDetection.detectedCells = [];
                        viewportRef.current.clearPaintCanvas();
                        var success = await FlatoutDataAPI.updateZoneMaps(
                            hierarchy.siteUnitId,
                            hierarchy.vesselId,
                            zones
                        );
                        if (success) {
                            showModal({
                                title: 'Done!',
                                message: 'Zone hierarchy saved successfully. Please identify zones again.',
                                class: 'success',
                            });
                        }
                    } catch (err) {
                        console.error(err);
                    } finally {
                        viewportRef.current.hideLoader();
                    }
                }
            } else {
                setZones(deepCopy(zonesBackup));
                setZonesBackup([]);
                zoneWizRef.current.resetTree();
                showModal({
                    title: 'Not saved!',
                    message: 'The changes were not saved, because the template is already published',
                    class: 'warning',
                });
            }
        }
    };

    return (
        <>
            <div className="upload-templates">
                <TopPanelUploadTemplates
                    ref={topPanelUploadTemplatesRef}
                    scale={scale}
                    sheet={sheet}
                    validating={validating}
                    analysing={analysing}
                    flatout={flatout}
                    saveRequired={isSaveRequired}
                    onClickSave={handleFlatoutSave.bind(this)}
                    onClickImageUpload={handleImageUpload.bind(this)}
                    onClickImageDelete={handleImageDelete.bind(this)}
                    onClickImageReUpload={handleImageReUpload.bind(this)}
                    onClickImagePublish={handleImagePublish.bind(this)}
                    onClickScaleSheetSize={() => setSheetPropDialogVisible(true)}
                    locked={locked}
                    onSetLock={(_lock) => {
                        setLocked(_lock);
                    }}
                    imageError={imageError}
                />

                <div style={{ display: 'none' }}>
                    <input
                        type={'file'}
                        accept="image/jpeg, image/png ,image/tiff, image/bmp, image/jpg, image/tif"
                        ref={filePicker}
                        onChange={(e) => {
                            onChangeImage(e);
                        }}
                    />
                </div>

                <Viewport
                    ref={viewportRef}
                    onInputStart={handleInputStart.bind(this)}
                    onInputEnd={handleInputEnd.bind(this)}
                    onInputUpdate={handleInputUpdate.bind(this)}
                    imageData={imageData}
                    transformEnabled={selectedTool === ActionType.TRANSFORM}
                    idleMessage="Select a Vessel to View Flatout Drawing"
                    rightChild={
                        zones.length && imageError === null && zoneColorMap.length > 0 ? (
                            <ZoneBoxUploadTemplates
                                zones={zones}
                                selectedZone={selectedZone}
                                zoneColorsMap={zoneColorMap}
                                disabled={selectedTool === ActionType.TRANSFORM || !locked}
                                onZoneClick={handleZoneSelection.bind(this)}
                                onZoneConfigureClick={() => {
                                    setZonesBackup(deepCopy(zones));
                                    setShowZoneWizard(imageError === null);
                                }}
                            />
                        ) : (
                            ''
                        )
                    }
                    leftChild={
                        <ToolsBarUploadTemplates
                            disabled={
                                imageData === null ||
                                validating ||
                                analysing ||
                                flatout === null ||
                                !locked ||
                                (flatout && flatout.isPublished)
                            }
                        />
                    }
                />
            </div>
            <Dialog
                header="Sheet Properties"
                visible={sheetPropDialogVisible}
                style={{ width: 280 }}
                onHide={() => setSheetPropDialogVisible(false)}
                className="aq-dialog"
                maskClassName="aq-dialog-backdrop"
                closeIcon="pi pi-times"
                resizable={false}
            >
                <table style={{ width: '100%', marginBottom: '-0.5rem' }}>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'right', width: 100, color: '#165688' }}>
                                <b>Scale:</b>
                            </td>
                            <td style={{ textAlign: 'right', maxWidth: 116 }}>
                                <div
                                    className="h2"
                                    style={{
                                        display: 'inline-block',
                                        fontSize: 'x-large',
                                        verticalAlign: 'middle',
                                        color: '',
                                    }}
                                >
                                    1 :
                                </div>
                                <InputNumber
                                    value={scale}
                                    onValueChange={(e) => {
                                        let _scale = scale;
                                        if (e.value != null) {
                                            _scale = e.value;
                                            if (_scale >= 1 && _scale <= 100) setScale(_scale);
                                            else setScale(scale);
                                            setIsSaveRequired(true);
                                        }
                                    }}
                                    min={10}
                                    max={100}
                                    mode="decimal"
                                    showButtons
                                    buttonLayout="vertical"
                                    style={{ width: '45%', verticalAlign: 'middle', marginLeft: 8, marginBottom: -8 }}
                                    incrementButtonIcon="pi pi-plus"
                                    decrementButtonIcon="pi pi-minus"
                                    incrementButtonClassName="aq-btn"
                                    decrementButtonClassName="aq-btn"
                                    inputStyle={{
                                        borderRadius: 0,
                                        background: '#fff8',
                                        border: '1px solid #0001',
                                        fontSize: 'large',
                                    }}
                                    minFractionDigits={0}
                                    maxFractionDigits={0}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'right', color: '#165688' }}>
                                <b>Sheet Size:</b>
                            </td>
                            <td style={{ textAlign: 'right' }}>
                                <Dropdown
                                    style={{ width: 114, textAlign: 'start', marginTop: 6 }}
                                    value={sheet}
                                    className="aq-select"
                                    options={sheets}
                                    optionLabel={'sheetSize'}
                                    onChange={(e) => {setSheet(e.value); setIsSaveRequired(true)}}
                                    placeholder="Select Sheet"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Dialog>
            {zones.length > 0 && (
                <ZoneWizard
                    ref={zoneWizRef}
                    visible={showZoneWizard}
                    onHide={() => setShowZoneWizard(false)}
                    zones={zones}
                    disableSaving={!locked}
                    onChangeZones={setZones}
                    onSave={saveZoneHierarchy.bind(this)}
                />
            )}
        </>
    );
}
