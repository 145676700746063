import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { AppRole } from '../../config/AuthConfig';
import { useSelector } from 'react-redux';
export default function SideBar({ currentPath = '' }) {
    const user = useSelector((state) => state.user);
    const hierarchy = useSelector((state) => state.hierarchy);
    const [modifiedNavData, setModifiedNavData] = useState([]);
    const envName = process.env.REACT_APP_ENV_NAME;
    const isOnline = useSelector((state) => state.networkConnectionStatus.isOnline)
    const loadingBlocker = useSelector((state)=>state.loadingblocker.loadingblocker)
    
    const navigationData = [
        { id: '1', route: '/upload', icon: 'pi pi-upload', title: 'Upload Templates', roles: [AppRole.ADMINISTRATOR] },
        { id: '2', route: '/', icon: 'pi pi-eye', title: 'Inspection Flatouts', roles: [] },
        { id: '3', route: '/mntnsched', icon: 'pi pi-wrench', title: 'Maintenance Planner', roles: [] },
        { id: '4', route: 'https://rms.devapps.alcoa.com', absolute: true, icon: 'pi pi-arrow-left', title: 'Go Back to RMS', roles: [] },
    ];

    useEffect(() => {
        var baseUrlPrefix = '';
        if (envName === 'Dev' || envName === 'Local') baseUrlPrefix = 'devapps';
        else if (envName === 'Prod') baseUrlPrefix = 'apps';
        else baseUrlPrefix ='testapps';

        let baseUrl = `https://rms.${baseUrlPrefix}.alcoa.com`;

        let route = currentPath === '/upload' ? '#/calciner-setup/nominal-lining' : '#/shutdown-execution/inspection'

        var params = [];
        if (hierarchy.siteId > 0) {
            params.push('siteId=' + hierarchy.siteId);
        }

        if (hierarchy.siteUnitId > 0) {
            params.push('unitId=' + hierarchy.siteUnitId);
        }

        if (hierarchy.vesselId > 0) {
            params.push('vesselId=' + hierarchy.vesselId);
        }

        if (hierarchy.outageId > 0) {
            params.push('outageId=' + hierarchy.outageId);
        }

        var paramString = '';

        if (params.length > 0) {
            paramString = ('?' + params.join('&'));
        }

        let _modifedNavData = [...navigationData];
        _modifedNavData[3].route = baseUrl + route + paramString;

        setModifiedNavData(_modifedNavData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hierarchy, envName])

    const navigationHTML = [];

    modifiedNavData.forEach((navItem) => {
        navigationHTML.push(
            navItem.roles.length === 0 || navItem.roles.includes(user.role) ?
            <a
                href={navItem.absolute ? navItem.route :'/#' + navItem.route}
                key={navItem.route}
                target={navItem.absolute ? '_blank' : '_self'}
                rel={navItem.absolute ? 'noreferrer' : ''}
                className={currentPath === navItem.route ? 'active' : ''}
            >
                <i className={'aq-nav-icon ' + navItem.icon} />
                {navItem.title}
            </a> : ''
        );
    });

    const [animatingBtn, setAnimatingBtn] = useState(false);
    const [animatingTitle, setAnimatingTitle] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [title, setTitle] = useState('');

    const openSidebar = () => {
        setAnimatingBtn(true);
        setTimeout(() => {
            setAnimatingBtn(false);
        }, 500);

        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        let _nav = navigationData.filter((x) => x.route === currentPath)[0];
        if (_nav) {
            setTitle(_nav.title);
        }

        setTimeout(() => {
            setSidebarOpen(false)
        }, 500);
       
        setAnimatingTitle(true);
        setTimeout(() => {
            setAnimatingTitle(false);
        }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPath]);

    return (
        <>
            <div className={'aq-nav ' + (sidebarOpen ? '' : 'hide')}>
                <div className="aq-nav-current">
                    <Button
                        title="Application Menu"
                        disabled = { loadingBlocker || !isOnline }
                        className={'aq-nav-current-btn ' + (animatingBtn ? 'animate' : '')}
                        icon="pi pi-bars"
                        onClick={openSidebar}
                    ></Button>
                    <span className={'aq-nav-current-title ' + (animatingTitle ? ' animate' : '')}>{title}</span>
                </div>
                <nav>{navigationHTML}</nav>
            </div>
            <div
                className={'aq-nav-backdrop' + (sidebarOpen ? ' active' : '')}
                onClick={() => setSidebarOpen(false)}
            ></div>
        </>
    );
}
